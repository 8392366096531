<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group :item="item" :order_id="order_id" :refresher="refresher" :toggleDetail="toggleDetail"/>
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item" />
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item" />
  </div>
  <div v-else-if="item.type === 'tab_datatable_with_search'">
    <PopUpTabDataTableWithSearch :item="item" />
  </div>
  <div v-else-if="item.type === 'dynamic_tab_datatable_with_search'">
    <PopUpDynamicTabDataTableWithSearch :item="item" />
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :item="item" />
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item" />
  </div>
  <div
    v-else-if="
      item.type === 'datatable_with_search' ||
      item.type === 'datatable_with_search_and_actions'
    "
  >
    <PopUpDataTableWithSearch :itemprop="item" />
  </div>
</template>

<script>
import PopUpDataTable from "@/own/components/fulfillment/orders/popup/PopUpDataTable.vue";
import PopUpTable from "@/own/components/fulfillment/orders/popup/PopUpTable.vue";
import PopUpTextGroup from "@/own/components/fulfillment/orders/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/fulfillment/orders/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine.vue";
import PopUpDataTableWithSearch from "@/own/components/datatable/popup/PopUpDataTableWithSearch.vue";
import PopUpTabDataTableWithSearch from "@/own/components/datatable/popup/PopUpTabDataTableWithSearch.vue";
import PopUpDynamicTabDataTableWithSearch from "@/own/components/datatable/popup/PopUpDynamicTabDataTableWithSearch.vue";
export default {
  name: "PopUpSelector",
  props: ["item", 'order_id', 'refresher', 'toggleDetail'],
  components: {
    PopUpDynamicTabDataTableWithSearch,
    PopUpTable,
    PopUpTextGroup,
    PopUpTimeLine,
    PopUpDocs,
    PopUpDataTable,
    PopUpDataTableWithSearch,
    PopUpTabDataTableWithSearch,
  },
};
</script>
